export const INITIAL_VALUE = {
  name: '',
  email: '',
  password: ''
};

export const DESCRIPTOR = {
  name: {
    type: 'string',
    required: true,
    min: 2,
    message: 'Must be at least 2 characters'
  },
  email: { type: 'email', required: true, message: 'Email is required' },
  password: {
    type: 'string',
    required: true,
    min: 8,
    max: 128,
    message: 'Must be at least 8 characters.'
  }
};
