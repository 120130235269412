import schema from 'async-validator';

export const validateFields = (descriptor, data) => {
  // eslint-disable-next-line
  const validator = new schema(descriptor);
  const fieldErrors = {};

  validator.validate(data, (errors) => {
    if (errors) {
      errors.forEach((err) => {
        fieldErrors[err.field] = err.message;
      });
    }
  });

  return fieldErrors;
};
